<template>
  <div>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <h2 class="pl-1">
      <strong>Move Case to A Client And Branch</strong>
    </h2>
    <hr>
    <b-row>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          name="Client Name"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Client Name"
            label-for="clientName"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="clientName"
              v-model="ClientName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="clientName"
              placeholder=""
              name="dealOwner"
              :disabled="readOnly"
              @change="alert('asd')"
            >
              <template #search="{ attributes, events }">
                <input
                  v-model="clientSearch"
                  class="vs__search"
                  v-bind="attributes"
                  :state="errors.length > 0 ? false : null"
                  :readonly="readOnly"
                  v-on="events"
                >
              </template>
            </v-select>
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
            <small
              v-if="errors.length < 1"
            >
              * Only active clients are shown
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          name="Branch Name"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Branch Name"
            label-for="branchName"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="branchName"
              v-model="BranchName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="branchName"
              placeholder=""
              name="branchName"
              :disabled="readOnly"
              :state="errors.length > 0 ? false : null"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
            <small
              v-if="errors.length < 1"
            >
              * Only active branches are shown
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          name="Requestor Name"
          rules="required"
        >
          <b-form-group
            class="required-data"
            label="Requestor Name"
            label-for="requesterName"
            :state="errors.length > 0 ? false : null"
          >
            <v-select
              id="requesterName"
              v-model="RequesterName"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="requesterName"
              placeholder=""
              name="requesterName"
              :disabled="readOnly"
              :state="errors.length > 0 ? false : null"
            />
            <small
              class="text-danger"
              :state="errors.length > 0 ? false : null"
            >
              {{ errors[0] }}
            </small>
            <small
              v-if="errors.length < 1"
            >
              * Only active requestors are shown
            </small>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <hr>
    <div class="block-create">
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            :disabled="!Client.ClientID"
            @click="createNewBranch()"
          >
            Create New Branch
          </b-button>
        </b-col>
        <b-col></b-col>
        <b-col class="text-right">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            :disabled="!Branch.BranchID"
            @click="createNewRequestor()"
          >
            Create New Requestor
          </b-button>
        </b-col>
      </b-row>
      <div class="bold-text">After creating a new branch or requestor, please refresh this page.</div>
    </div>
    <b-row class="pt-5">
      <b-col>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          class="mr-2"
          type="reset"
          variant="danger"
          @click="resetForm()"
        >
          Cancel
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          @click="saveForm()"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BBreadcrumb, BButton, BCol, BFormGroup, BRow} from "bootstrap-vue";
import vSelect from "vue-select";
import {ValidationProvider} from "vee-validate";
import {mapGetters, mapMutations} from "vuex";
import APIService from "@core/utils/APIService";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { tryGetDate } from '@core/utils/filter';

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    ValidationProvider,
    BBreadcrumb,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      caseObject: {},
      CaseID: "",
      Branch: {
        BranchID: "",
        Name: "",
        Address: "",
        City: "",
        StateProvince: "",
        ZipCode: "",
      },
      Requester: {
        UserAccountID: "",
        Name: "",
        Email: "",
        Phone: "",
      },
      Client: {
        ClientID: "",
        Name: "",
      },

      ClientName: "",
      BranchName: "",
      RequesterName: "",

      PrevClientName: "",
      PrevBranchName: "",

      clientSearch: "",

      clientName: [],
      branchName: [],
      requesterName: [],

      readOnly: false,
      required,
      tryGetDate,
    }
  },
  computed: {
    ...mapGetters({
      getPostData: "cases/getPostData",
    }),
    breadcrumbItems: function() {
      return [
        {
          text: 'Cases',
          href: '/cases'
        },
        {
          text: this.caseObject.FileNumber,
        },
      ]
    },
  },
  watch: {
    ClientName(val) {
      if (val === null) {
        this.clearClient();
        this.clearBranch();
        this.clearRequester();
      }
      if (val && this.PrevClientName && this.PrevClientName !== val.ClientID) {
        this.clearBranch();
        this.clearRequester();
      }
      this.clientSearch = "";
      this.PrevClientName = val && val.ClientID ? val.ClientID : null;
      if (val && val.ClientID) this.loadClient(val.ClientID);
    },
    clientSearch(val) {
      if (val.length >= 3) {
        this.clearClient();
        this.clearBranch();
        this.clearRequester();
        this.searchClients(val)
      }
    },
    RequesterName(val) {
      if (val === null) this.clearRequester();
      if (val && val.id) this.loadRequester(val.id);
    },
    BranchName(val) {
      if (val === null) {
        this.clearBranch();
        this.clearRequester();
      }
      if (val && this.PrevBranchName && this.PrevBranchName !== val.id) {
        this.clearRequester();
      }
      this.PrevBranchName = val && val.id ? val.id : null;
      if (val && val.id) this.loadBranch(val.id);
    },
    CaseID(val) {
      this.caseObject = {...this.caseObject, CaseID: val};
      this.setCase(this.caseObject);
    }
  },
  async created() {
    try {
      var existingCaseID = this.$route.params.caseID;
      if (existingCaseID) {
        apiService
            .get("case/" + existingCaseID + "/detail")
            .then((response) => {
              this.caseObject = response.data;
              this.setCase(this.caseObject);
            })
            .then(() => {
              this.setCaseData(this.caseObject)
            })
      }
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    ...mapMutations({
      setCase: "cases/setCase",
      setCurrentClient: "cases/setCurrentClient",
    }),
    showToast(variant, position, timeout, data) {
      this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notification',
              icon: 'InfoIcon',
              text: data,
              variant,
            },
          },
          {
            position,
            timeout,
          })
    },
    searchClients(searchNameTerm) {
      apiService
          .get(
              "clients/search?searchNameTerm=" +
              encodeURIComponent(searchNameTerm) +
              "&activeClients=" +
              !this.activeClients
          )
          .then((response) => {
            this.clientName = response.data.Clients.map((item, int) => {
              return {title: item.Name, value: item.Name, id: int, ClientID: item.ClientID}
            });
          });
    },
    loadClient(ClientId) {
      if (ClientId !== this.Client.ClientID) {
        apiService
            .get("client/" + ClientId + "/detail")
            .then((response) => {
              this.branchName = response.data.Branches.map(item => {
                return {title: item.Name, value: item.Name, id: item.ID}
              });
              this.Client.ClientID = ClientId;
              this.Client.Name = response.data.Name;
              this.caseObject.Branch.Client = {...this.caseObject.Branch.Client, ...this.Client};
              this.setCase(this.caseObject);
            })
      }
    },
    loadBranch(branchId) {
      if (branchId !== Number(this.Branch.BranchID)) {
        apiService
            .get("client/branch/" + branchId + "/detail")
            .then((response) => {
              this.fillBranch(response.data)
            });
        apiService
            .get("client/branch/"  + branchId + "/requestors?max=10000")
            .then((response) => {
              this.requesterName = response.data.map(item => {
                return {title: item.Name, value: item.Name, id: item.ID}
              });
            });
      }
    },
    loadRequester(requesterId) {
      if (requesterId && requesterId !== Number(this.Requester.UserAccountID)) {
        apiService
            .get("client/requestor/" + requesterId + "/detail")
            .then((response) => {
              this.Requester = {
                UserAccountID: response.data.UserAccountID,
                Name: response.data.LastName + ", " + response.data.FirstName,
                Email: response.data.EMail,
                Phone: response.data.Phone,
              };
              this.RequesterName = this.Requester.Name;
              this.caseObject.Branch.Requester = {...this.caseObject.Branch.Requester, ...this.Requester};
              this.setCase(this.caseObject);
            });
      }
    },
    fillBranch(val) {
      this.Branch.BranchID = val.BranchID;
      this.Branch.Name = val.Name;
      this.Branch.Address = val.Address1 || val.Address;
      this.Branch.City = val.City;
      this.Branch.StateProvince = val.StateProvince;
      this.Branch.ZipCode = val.ZipCode;
      this.caseObject.Branch = {...this.caseObject.Branch, ...this.Branch};
      this.setCase(this.caseObject);
    },
    setCaseData(cases) {
      if (cases && cases.Branch) {
        if (cases.Branch.Client.ClientID) {
          this.ClientName = {
            title: cases.Branch.Client.Name,
            value: cases.Branch.Client.Name,
            id: cases.Branch.Client.ClientID,
            ClientID: cases.Branch.Client.ClientID,
          };
          this.loadClient(cases.Branch.Client.ClientID)
          this.Client.Name = cases.Branch.Client.Name;
          this.Client.ClientID = cases.Branch.Client.ClientID;
        }
        if (cases.Branch.Name) {
          this.BranchName = {
            title: cases.Branch.Name,
            value: cases.Branch.Name,
            id: cases.Branch.BranchID,
          }
          this.loadBranch(cases.Branch.BranchID)
        }
        if (cases.Branch.Requester.UserAccountID) {
          this.Requester = cases.Branch.Requester;
          this.RequesterName = cases.Branch.Requester.Name;
          this.loadRequester(cases.Branch.Requester.UserAccountID)
        }
        if (cases.CaseID) {
          this.CaseID = cases.CaseID
        }
      }
    },
    clearBranch() {
      this.BranchName = "";
      this.Branch.BranchID = "";
      this.Branch.Name = "";
      this.Branch.Address = "";
      this.Branch.City = "";
      this.Branch.StateProvince = "";
      this.Branch.ZipCode = "";
      this.caseObject.Branch = {...this.caseObject.Branch, ...this.Branch};
      this.setCase(this.caseObject);
    },
    clearRequester() {
      this.Requester.UserAccountID = "";
      this.Requester.Email = "";
      this.Requester.Phone = "";
      this.Requester.Name = "";
      this.RequesterName = "";
      this.caseObject.Branch.Requester = {...this.caseObject.Branch.Requester, ...this.Requester};
      this.setCase(this.caseObject);
    },
    clearClient() {
      this.Client.Name = "";
      this.Client.ClientID = "";
      this.caseObject.Branch.Client = {...this.caseObject.Branch.Client, ...this.Client};
      this.setCase(this.caseObject);
    },
    createNewBranch() {
      window.open('/clients/' + this.Client.ClientID + '/branch/add', '_blank');
    },
    createNewRequestor() {
      window.open('/client/requestor/add/' + this.Client.ClientID + '/' + this.Branch.BranchID, '_blank');
    },
    resetForm() {
      this.$router.push('/cases/' + this.CaseID + "/detail");
    },

    saveForm() {
      const postData = {
        "CaseID": this.CaseID,
        "ClientID": this.ClientName.ClientID,
        "BranchID": this.BranchName.id,
        "PrimaryRequestorUserID": this.Requester.UserAccountID
      };
      if (!this.CaseID || !this.ClientName || !this.ClientName.ClientID ||
          !this.BranchName || !this.BranchName.id || !this.Requester || !this.Requester.UserAccountID) {
        this.showToast('warning', 'top-center', 4000, 'All fields required!');
        return;
      }
      apiService.put("case/client", postData).then(res => {
        if (res) {
          this.showToast('success', 'top-center', 4000, 'Case Saved!');
          this.$router.push('/cases/' + this.CaseID + "/detail");
        }
      })
    },
  },
}
</script>

<style scoped>

.block-create {
  border: 1px solid;
  padding: 1rem;
  width: 49%;
  margin-left: initial;
}
.bold-text {
  font-weight: 900;
  text-align: center;
  padding-top: 1rem;
}
</style>